import { EventEmitter, Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { HttpService } from "./http.service";

@Injectable({
    providedIn: 'root'
})
export class WebRTCService {
    public onVideoLoaded = new EventEmitter<boolean>();
    presenterSubject = new BehaviorSubject<any | null>(null);

    peer: RTCPeerConnection | null | undefined;
    streamId!: string;
    sessionId!: string;
    offer: any;
    iceServers: any;
    sessionClientAnswer: any;
    videoIsPlaying: any;

    headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": `Bearer ${this.config.getAnonymousId()}`
    });

    constructor(private config: ConfigService, private http: HttpService) {}

    // getters
    getStreamId() {
        return this.streamId;
    }

    getSessionId() {
        return this.sessionId;
    }

    getOffer() {
        return this.offer;
    }

    getIceServers() {
        return this.iceServers;
    }

    // setters
    setStreamId(streamId: string) {
        this.streamId = streamId;
    }

    setSessionId(sessionId: string) {
        this.sessionId = sessionId;
    }

    setOffer(offer: any) {
        this.offer = offer;
    }

    setIceServers(iceServers: any) {
        this.iceServers = iceServers;
    }

    async initializePeerConnection() {
        if (this.peer && this.peer.connectionState === 'connected') {
            console.log("WebRtc already connected");
            await this.reconnect();
            return;
        }

        const sessionResponse = await this.createStream();

        type SessionData = { id: string; offer: any; ice_servers: any[]; session_id: string; };
        const { id, offer, ice_servers, session_id } = sessionResponse.body as SessionData;

        this.setStreamId(id);
        this.setOffer(offer);
        this.setIceServers(ice_servers);
        this.setSessionId(session_id);

        try {
            this.sessionClientAnswer = await this.createPeerConnection();
        } catch (e) {
            console.log('Error during streaming setup', e);
            await this.reconnect();
            return;
        }

        await this.createSdpConnection(this.sessionClientAnswer);
    }

    // Initiate a video streaming session -> return streamId, sessionId
    async createStream() {
        this.config.getPresenter().subscribe(presenter => {
            if (presenter) { this.presenterSubject.next(presenter); }
        });

        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/webrtc/stream`)
                    .setMethod("POST")
                    .setContent({
                        "source_url": this.presenterSubject.value,
                    })
                    .setHeaders(this.headers)
                    .create()
            );
        } catch (e: any) {
            console.log("Error while creating d-id stream: ", e);
            return {
                status: 500,
                body: {}
            }
        }
    }

    async createPeerConnection() {
        if (!this.peer) {
            let iceServers = this.getIceServers();
            this.peer = new RTCPeerConnection({iceServers});
            this.peer.addEventListener('icegatheringstatechange', () => this.onIceGatheringStateChange.call(this), true);
            this.peer.addEventListener('icecandidate', event => this.onIceCandidate.call(this, event), true);
            this.peer.addEventListener('iceconnectionstatechange', () => this.onIceConnectionStateChange.call(this), true);
            this.peer.addEventListener('connectionstatechange', () => this.onConnectionStateChange.call(this), true);
            this.peer.addEventListener('signalingstatechange', () => this.onSignalingStateChange.call(this), true);
            this.peer.addEventListener('track', event => this.onTrack.call(this, event), true);
        }

        await this.peer.setRemoteDescription(this.getOffer());
        console.log('Set remote sdp OK');

        const sessionClientAnswer = await this.peer.createAnswer();
        console.log('Create local sdp OK', sessionClientAnswer);

        await this.peer.setLocalDescription(sessionClientAnswer);
        console.log('Set local sdp OK');

        return sessionClientAnswer;
    }

    // Start a stream
    async createSdpConnection(sessionClientAnswer: any) {
        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/webrtc/streams/${this.getStreamId()}/sdp`)
                    .setMethod("POST")
                    .setContent({
                        "answer": sessionClientAnswer,
                        "session_id": this.getSessionId()
                    })
                    .setHeaders(this.headers)
                    .create()
            );
        } catch (e: any) {
            console.log("Error while creating sdp connection: ", e);
            return {
                status: 500,
                body: {}
            }
        }
    }

    // events
    onIceGatheringStateChange() {
        //console.log(this.peer!.iceGatheringState)
    }

    // Submit network information
    onIceCandidate(event: any) {
        if (event.candidate) {
            const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

            try {
                return lastValueFrom(
                    this.http
                        .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/webrtc/streams/${this.getStreamId()}/ice`)
                        .setMethod("POST")
                        .setContent({
                            "candidate": candidate,
                            "sdpMid": sdpMid,
                            "sdpMLineIndex": sdpMLineIndex,
                            "session_id": this.getSessionId()
                        })
                        .setHeaders(this.headers)
                        .create()
                );
            } catch (e: any) {
                console.log("Error while creating sdp connection: ", e);
                return {
                    status: 500,
                    body: {}
                }
            }
        } else {
            return {
                status: 400,
                body: { message: 'No candidate present in the event' }
            };
        }
    }

    async onIceConnectionStateChange() {
        //console.log(this.peer!.iceConnectionState)

        if (this.peer!.iceConnectionState === 'failed' || this.peer!.iceConnectionState === 'closed' || this.peer!.iceConnectionState === 'disconnected') {
            await this.reconnect();
        }
    }

    onConnectionStateChange() {
        console.log(this.peer!.connectionState)
    }

    onSignalingStateChange() {
        //console.log(this.peer!.signalingState)
    }

    onTrack(event: any) {
        if (!event.track) return;

        let intervalId: any;
        let lastBytesReceived = 0;

        const intervalStats = async () => {
            try {
                const stats = await this.peer!.getStats(event.track);
                stats.forEach((report) => {
                    if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
                        const videoStatusChanged = this.videoIsPlaying !== report.bytesReceived > lastBytesReceived;

                        if (videoStatusChanged) {
                            this.videoIsPlaying = report.bytesReceived > lastBytesReceived;
                            if (this.videoIsPlaying) {
                                if (event.streams && event.streams.length > 0) {
                                    const remoteStream = event.streams[0];
                                    const rs = new MediaStream();

                                    remoteStream.getTracks().forEach((track: any) => {
                                        rs.addTrack(track);
                                    });

                                    if (!rs) return;
                                    this.onVideoLoaded.emit(true);
                                    this.config.setDIdVideo(rs);
                                }
                            } else {
                                this.onVideoLoaded.emit(false);
                                this.config.setDIdVideo(null);
                            }
                        }
                        lastBytesReceived = report.bytesReceived;
                    }
                });
            } catch (e) {
                console.log('Error during stats retrieval', e);
            }

            if (!event.track.ended) {
                intervalId = setTimeout(intervalStats, 500);
            }
        }

        intervalId = setTimeout(intervalStats, 500);
        event.track.onended = () => clearTimeout(intervalId);
    }

    // Create a talk stream
    // Cant use facial expressions - d-id doesn't support this option on stream creation
    // Blurring problem with result video - its about used Premium/Trial Plan
    async createTalkStream(content: string, voiceId: string) {
        try {
            const response = await lastValueFrom(
                this.http
                    .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/webrtc/streams/${this.getStreamId()}`)
                    .setMethod("POST")
                    .setContent({
                        "script": {
                            "type": "text",
                            "provider": {
                                "type": "microsoft",
                                "voice_id": voiceId
                            },
                            "ssml": "false",
                            "input": content
                        },
                        "config": {
                            "fluent": "false",
                            "pad_audio": "0.0",
                            "stitch": true
                        },
                        "audio_optimization": "2",
                        "session_id": this.getSessionId(),
                    })
                    .setHeaders(this.headers)
                    .create()
            );

            console.log(response);
            return response;
        } catch (e: any) {
            console.log("Error while creating talk stream: ", e);
            return {
                status: 500,
                body: {}
            }
        }
    }

    async reconnect() {
        return setTimeout(async () => {
            this.closePeerConnection();
            await this.initializePeerConnection();
        }, 500);
    }

    closePeerConnection(){
        const peer = this.peer;
        if (!peer) return;

        peer.close();

        peer.removeEventListener('icegatheringstatechange', () => this.onIceGatheringStateChange.call(this), true);
        peer.removeEventListener('icecandidate', event => this.onIceCandidate.call(this, event), true);
        peer.removeEventListener('iceconnectionstatechange', () => this.onIceConnectionStateChange.call(this), true);
        peer.removeEventListener('connectionstatechange', () => this.onConnectionStateChange.call(this), true);
        peer.removeEventListener('signalingstatechange', () => this.onSignalingStateChange.call(this), true);
        peer.removeEventListener('track', event => this.onTrack.call(this, event), true);

        this.peer = null
    }
}
