import {
    Component,
    OnInit,
    ElementRef,
    OnDestroy, ViewChild
} from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { TypedService } from 'app/services/typed.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { AvatarInteractionService } from "app/services/avatar-interaction.service";
import { MessageDisplayFormat } from "app/models/messageDisplayFormat";
import { InteractionService } from "app/services/interaction.service";
import { MessagesService } from "app/services/messages.service";

@Component({
    selector: 'app-message-on-audio',
    templateUrl: './message-on-audio.component.html',
    styleUrl: './message-on-audio.component.scss'
})
export class MessageOnAudioComponent extends ComponentAbstract implements OnInit, OnDestroy {
    @ViewChild('typedElement') typedElementRef!: ElementRef;
    clickedThumbColor: string = 'rgba(85, 152, 254, 1)';
    defaultThumbColor: string = 'rgba(49, 64, 90, 1)';
    isHoveredThumbUp: boolean = false;
    isHoveredThumbDown: boolean = false;

    typedSelector: string = '.avatar-answer-text'
    isVisible: boolean = false;
    isInteractionAllowed: boolean = false;
    voiceMessage!: MessageDisplayFormat;

    visibilitySubscription!: Subscription;
    interactionSubscription!: Subscription;
    voiceMessageSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private typed: TypedService,
        private multimedia: MultimediaService,
        private visibility: AvatarInteractionService,
        private interaction: InteractionService,
        protected message: MessagesService
    ) {
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-message-on-audio').subscribe(visible => {
            this.isVisible = visible;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.voiceMessageSubscription = this.message.getVoiceMessage().subscribe(voiceMessage => {
            this.voiceMessage = voiceMessage
            this.initTypedElements().then();
        });
    }

    async initTypedElements() {
        await new Promise<void>(resolve => {
            setTimeout(() => {
                if (this.typedElementRef && this.typedElementRef.nativeElement) {
                    this.typed.initTyped(this.typedSelector, this.voiceMessage.content);
                }
                resolve();
            });
        });
    }

    emitMediaType(mediaType: string, source: string) {
        this.multimedia.emitMediaData(mediaType, source);
    }

    positiveReaction() {
        this.message.setVoiceMessageFeedback(true)
    }

    negativeReaction() {
        this.config.setClickedMessageFeedbackStatus(false)
        this.visibility.showComponent("avatar-feedback");
    }

    handleThumbDownColor(message: MessageDisplayFormat, hoveredThumbDown: boolean): string {
        return this.handleThumbColor(message, hoveredThumbDown);
    }

    handleThumbUpColor(message: MessageDisplayFormat, hoveredThumbUp: boolean): string {
        return this.handleThumbColor(message, hoveredThumbUp);
    }

    //helpers
    handleThumbColor(message: MessageDisplayFormat, hovered: boolean): string {
        return (message.feedback !== undefined) ? this.clickedThumbColor :
            (hovered ? this.clickedThumbColor : this.defaultThumbColor);
    }
    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
        this.interactionSubscription.unsubscribe();
        this.voiceMessageSubscription.unsubscribe();
    }
}
