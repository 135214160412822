<div *ngIf="isVisible" class="avatar-conversation-board">
    <div class="avatar-conversation-heading">
        <div class="assistant-identity">
            <div class="assistant-logo">
                <img [src]="avatarIcon" class="avatar-icon" alt="Avatar AI icon"/>
            </div>
            <h4>{{ assistantName }}</h4>
        </div>
        <app-avatar-heading class="avatar-top-section" [avatarTab]="'avatar-conversation-board'"></app-avatar-heading>
    </div>
    <div class="avatar-conversation" [ngClass]="{'blurred': isBlured}">
        <div class="avatar-conversation-wrapper" #scrollContainer>
            <div class="scroll">
                <app-message></app-message>
            </div>
        </div>
        <span *ngIf="isLoadingShown" class="loader"></span>
    </div>
</div>

<app-modal-content idModal="idModal" labelModal="labelModal"></app-modal-content>
