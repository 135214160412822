import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Typed from 'typed.js';

import { InteractionService } from "./interaction.service";

@Injectable({
  providedIn: 'root',
})
export class TypedService {
    isTypingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isTyping$ = this.isTypingSubject.asObservable();

    constructor(private interaction: InteractionService) { }

    initTyped(selector: string, text: string) {
        this.isTypingSubject.next(true);
        const options = {
            strings: [text],
            typeSpeed: 25,
            showCursor: false,
            onComplete: () => {
                this.isTypingSubject.next(false);
            },
        };
        new Typed(selector, options);
    }
}
