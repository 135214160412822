import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { LanguageService } from "app/services/language.service";
import { MessagesService } from "app/services/messages.service";
@Component({
  selector: 'app-avatar-feedback',
  templateUrl: './avatar-feedback.component.html',
  styleUrl: './avatar-feedback.component.scss'
})
export class FeedbackComponent extends ComponentAbstract implements OnInit, OnDestroy {
    isVisible: boolean = false;
    placeholderText: string = 'Type your additional feedback...';
    feedbackText: string = 'Provide additional feedback';
    harmfulCheckboxText: string = 'This is harmful/unsafe';
    helpfulCheckboxText: string = 'This isn’t helpful';
    trueCheckBoxText: string = 'This isn’t true';
    buttonText: string = 'Submit';
    additionalFeedback!: string;
    textDirection!: string;

    checkboxes = [
        { id: 'reason-1', text: this.harmfulCheckboxText, checked: false},
        { id: 'reason-2', text: this.trueCheckBoxText, checked: false },
        { id: 'reason-3', text: this.helpfulCheckboxText, checked: false },
    ];

    translateSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private language: LanguageService,
        private messages: MessagesService
    ) {
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-feedback').subscribe(visible => {
            this.isVisible = visible;
            if (this.isVisible) {
                this.config.blurEfectEvent.emit(true);
               ["avatar-drag-drop", "avatar-shortcuts"].map(key => this.visibility.hideComponent(key));
            }
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale);

            Object.assign(this, translate.typography);
            const checkboxTexts = [this.harmfulCheckboxText, this.trueCheckBoxText, this.helpfulCheckboxText];
            this.checkboxes.forEach((checkbox, index) => {checkbox.text = checkboxTexts[index]; });
        });
    }

    closeFeedback() {
        this.additionalFeedback = '';
        this.checkboxes.forEach(checkbox => {checkbox.checked = false; });
        this.config.blurEfectEvent.emit(false);
        this.visibility.hideComponent("avatar-feedback");
    }

    submitNegativeReaction() {
        if (this.config.getClickedMessageFeedbackStatus()){
            const index = this.messages.getMessageIndex();
            if (index != null) {
                this.messages.setMessageFeedback(index, false, this.additionalFeedback);
                this.closeFeedback();
            } else {
                console.warn("Invalid message index.");
            }
        } else {
            this.messages.setVoiceMessageFeedback(false)
            this.closeFeedback();
        }
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
    }
}
