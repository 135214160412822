import { Component, OnDestroy, OnInit } from '@angular/core';

import { ConfigService } from 'app/services/config.service';
import { ComponentAbstract } from "app/components/component.abstract";
import { AvatarInteractionService } from "app/services/avatar-interaction.service";
import { LanguageService } from "app/services/language.service";
import { Subscription } from "rxjs";
import { InteractionService } from "app/services/interaction.service";

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss'
})
export class LoadingComponent extends ComponentAbstract implements OnInit, OnDestroy {
    loadingText: string = "I'm on a quest to find your answer...";
    isVisible: boolean = false;
    textDirection!: string;

    translateSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private language: LanguageService,
        private interaction: InteractionService
    ) {
        super(config);
    }

     ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-loading-audio').subscribe(visible => {
            this.isVisible = visible;
            if (this.isVisible) {
                ["avatar-conversation-board", "avatar-shortcuts", "avatar-feedback",
                    "avatar-drag-drop"].map(key => this.visibility.hideComponent(key));
                this.interaction.isLoading.next(false);
            }
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale);

            this.loadingText = translate.typography.loadingText;
        });
    }

    ngOnDestroy(): void {
        this.visibilitySubscription.unsubscribe();
    }
}
