<div *ngIf="isVisible" class="avatar-container">
    <div class="avatar-background-container">
        <div class="avatar-background-image">
            <app-avatar-video-presenter></app-avatar-video-presenter>
            <app-avatar-heading [avatarTab]="'avatar'"></app-avatar-heading>
            <app-avatar-shortcuts></app-avatar-shortcuts>
            <app-avatar-feedback></app-avatar-feedback>
            <app-avatar-drag-drop></app-avatar-drag-drop>
            <app-loading></app-loading>
            <app-message-on-audio></app-message-on-audio>
            <app-avatar-conversation-board></app-avatar-conversation-board>
            <app-avatar-controls></app-avatar-controls>
        </div>
    </div>
</div>
