import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { InteractionService } from "app/services/interaction.service";
@Component({
  selector: 'app-avatar-heading',
  templateUrl: './avatar-heading.component.html',
  styleUrl: './avatar-heading.component.scss'
})
export class AvatarHeadingComponent extends ComponentAbstract implements OnInit, OnDestroy {
    @Input() avatarTab!: string
    isInteractionAllowed: boolean = false;

    interactionSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private interaction: InteractionService
    ) {
        super(config);
    }

    ngOnInit(): void {
        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });
    }

    closeAvatar() {
        if (this.avatarTab == 'avatar-conversation-board'){
            this.visibility.hideComponent("avatar-conversation-board");
            this.config.setConversationTabStatusMode(false);
        } else if (this.avatarTab == 'avatar'){
            this.visibility.hideComponent("avatar");
        }
    }

    ngOnDestroy(): void {
        this.interactionSubscription.unsubscribe();
    }
}
