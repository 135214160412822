<div *ngIf="isVisible" class="avatar-answer-container">
    <div class="avatar-answer-wraper">
        <div class="avatar-inside-wraper">
            <div class="avatar-answer-text-box">
                <p #typedElement class="avatar-answer-text"></p>
            </div>
            <div *ngIf="voiceMessage.attachments && voiceMessage.attachments.length > 0" class="buttons-wraper">
                <button
                    *ngFor="let attachment of voiceMessage.attachments"
                    type="button"
                    class="ai-content-button"
                    data-bs-toggle="modal"
                    data-bs-target="#idModal"
                    (click)="emitMediaType(attachment.type, attachment.url)">
                    <div class="media-wrapper">
                        <img *ngIf="attachment.type=='image'" [src]="attachment.url" class="image-wrapper" alt="AI Image">
                        <div *ngIf="attachment.type=='video'" class="video-wrapper">
                            <video [src]="attachment.url" ></video>
                            <div class="play-video-icon">
                                <img alt="Play video icon">
                            </div>
                        </div>
                        <button *ngIf="attachment.type=='document'" class="avatar-pdf-button">
                            <img alt="Pdf icon"/>
                        </button>
                    </div>
                </button>
            </div>
            <div class="feedback-container">
                <button
                    class="feedback-thumb-up"
                    [disabled]="!isInteractionAllowed"
                    *ngIf="voiceMessage.feedback === true || voiceMessage.feedback === undefined"
                    type="button"
                    (click)="positiveReaction()"
                    (mouseover)=" isHoveredThumbUp = true"
                    (mouseout)="isHoveredThumbUp = false">
                    <app-thumb-up [thumbsColor]="handleThumbUpColor(voiceMessage, isHoveredThumbUp)"></app-thumb-up>
                </button>
                <button
                    [disabled]="!isInteractionAllowed"
                    *ngIf="voiceMessage.feedback === false || voiceMessage.feedback === undefined"
                    type="button"
                    (click)="negativeReaction()"
                    (mouseover)="isHoveredThumbDown = true"
                    (mouseout)="isHoveredThumbDown = false">
                    <app-thumb-down [thumbsColor]="handleThumbDownColor(voiceMessage, isHoveredThumbDown)"></app-thumb-down>
                </button>
            </div>
        </div>
    </div>
</div>

